

body {
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif;
  color: #40464a;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

a{
  text-decoration: none;
  color: #40464a;
}

*{
  box-sizing: border-box;
  font-family: "Oswald", sans-serif;
}


h2{
  text-align: center;
  font-size: 48px;
}

@media screen and (max-width: 767.5px) {
  h2{
    margin-top: 20px;
    font-size: 42px;
  }
  
}