header{
    width: 100%;
    background-color: #f5f5f5;
    color: #40464a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
    padding: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    height: max-content;
}

header img{
    max-width: 160px;
}

.header_tabs{
    display: flex;
    align-items: center;
    gap: 45px;
}


@media screen and (max-width: 767.5px){
   .header_tabs{
    display: none;
   }
   header img{
    max-width: 140px;
}
}