.container{
    display: flex;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
    flex-direction: column;
    overflow-x: hidden;
}


.loading{
    position: fixed;
    background:#f5f5f5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    
}

.loading img{
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}

