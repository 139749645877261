.concerts{
    display: flex;
    width: 100%;
    max-width: 1140px;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 20px;
    padding-bottom: 0;
    flex-direction: column;
    margin-bottom: 40px;
}

.concerts__list{
    display: flex;
    flex-direction: column;
}