

.main_title{
    display: flex;
    width: 100%;
    max-width: 1140px;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 20px;
    padding-bottom: 0;
}

.main_title h1{
    font-size: 68px;
    font-weight: bold;
    color: #40464a;
    margin-top: 0;
    line-height: 80px;
}

.main_title img{
    max-width: 36%;
}


.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.title .button_title{
    background: none;
    border: 3px solid #40464a;
    font-size: 21px;
    font-weight: bold;
    padding: 20px;
    width: max-content;
    cursor: pointer;
    transition: 0.3s all;
}


.title .button_title:hover{
    background-color: #40464a;
    color: #f5f5f5;
}

.title .button_title:hover{
    color: #f5f5f5;
}


@media screen and (max-width: 767.5px){
    .title .button_title{
        position: absolute;
        bottom: 40px;
        left: 35%;
        font-size: 14px;
        padding: 10px;
        border: 2px solid #f5f5f5;
    }
    .title .button_title{
        color: #f5f5f5;
    }
    .main_title h1{
        font-size: 46px;
        margin-bottom: 10px;
        line-height: 55px;
        text-align: center;
    }

    .main_title{
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        position: relative;
    }
    .main_title img{
        max-width: 75%;
    }
}