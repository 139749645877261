.video{
    display: flex;
    width: 100%;
    max-width: 1140px;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 20px;
    padding-bottom: 0;
    flex-direction: column;
    margin-bottom: 40px;
}

.video_height{
    height:550px;
}

.video_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


@media screen and (max-width: 767.5px) {
    .video{
      padding-top: 0;
    }

    .video_height{
        height:350px;
    }
    
  }