.concert_card{
    width: 100%;
    border-top: 0.5px solid #40464a0f;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 20px 0;
}

.concert_card:last-child{
    border-bottom: 0.5px solid #40464a0f;
}


.concert_card_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.concert_card__top{
    text-transform: uppercase;
    font-size: 21px;
    font-weight: bold;
}

.concert_card__top__top {
    text-shadow: #40464a 1px 0 25px;
    font-size: 24px;
}

.concert_card__big_concert{
    font-size: 12px;
    color:#ff0000;
    text-transform: uppercase;
}

.concert_card__bottom{
    text-transform: uppercase;
    font-size: 21px;
    font-weight: bold;
    color:#8d9397;
    text-align: center;
}

.concert_card_button{
    background-color: #f5f5f5;
    border: 2px solid #40464a;
    padding: 10px 20px;
    width: max-content;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all;
}

.concert_card_section_button{
    display: flex;
    justify-content: center;
    align-items: center;
}


.concert_card_button:hover{
    background-color: #40464a;
    color: #f5f5f5;
}


@media screen and (max-width: 767.5px){
    .concert_card{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .date{
        font-weight: 400;
        font-size: 18px;
    }

}