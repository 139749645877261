.music{
    display: flex;
    width: 100%;
    max-width: 1140px;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 20px;
    padding-bottom: 0;
    flex-direction: column;
    margin-bottom: 40px;
}

.music__list{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 767.5px) {
    .music{
      padding-top: 0;
    }
    
  }