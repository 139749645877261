.contacts{
    background-color: #40464a;
    width: 100%;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}


.contacts_wrapper{
    display: flex;
    max-width: 1140px;
    width: 100%;
    color: #f5f5f5;
    align-items: center;
    justify-content: space-between;
}

.soldout{
    text-transform: uppercase;
    color: #f5f5f5;
    font-size: 20px;
    padding: 20px 30px;
    border: 2px solid #f5f5f5;
    width: max-content;
    transition: 0.3s all;
}

.soldout:hover{
    background-color: #f5f5f5;
    color: #40464a;
}

.media{
   display: flex;
   gap: 40px;
}

.media a{
    color: #b7b7b7;
    transition: 0.3s all;
    font-size: 20px;
}

.media a:hover{
    color: #f5f5f5;
    
}

.media_section{
    display: flex;
    gap: 10px;
    align-items: center;
}

.media_section img{
    width: 35px;
    color: #b7b7b7;
}


.personal-contacts{
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 18px;
}

@media screen and (max-width: 1023.5px) {
    .contacts_wrapper{
        flex-direction: column;
        gap: 40px;
    }
    .media{
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;
    }
    .personal-contacts{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        font-size: 18px;
    }
    
}

@media screen and (max-width: 767.5px) {
    h2{
      margin-top: 20px;
      font-size: 42px;
    }
    .contacts{
        padding: 20px;
    }
    .contacts_wrapper{
        flex-direction: column;
        gap: 40px;
    }
    .media{
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;
    }
  }